import {Description, Edit} from "@material-ui/icons";
import MaterialTable, { MaterialTableProps } from "material-table";
import React, { useEffect, useState } from "react";
import PageRequest from "../../core/api/PageRequest";
import PageResponse from "../../core/api/PageResponse";
import { icons } from "../TableIcons";
import InvoiceModalView from "./InvoiceModalView";
import EmailIcon from "@material-ui/icons/Email";
import PrintIcon from "@material-ui/icons/Print";
import InvoiceEmailModalForm from "../email/InvoiceEmailModalForm";
import InvoicePrintComponent from "./InvoicePrintComponent";
import InvoiceEditModalForm from "./InvoceEditModalForm";
import InvoiceModel from "../../core/api/invoice/InvoiceModel";
import {useNotifications} from "../../hooks/NotificationProvider";

export interface InvoiceTableProps {
  clientId?: string;
  fetchFunction: (page: PageRequest) => Promise<PageResponse<InvoiceModel>>;
  tableRef: React.RefObject<MaterialTableProps<any>>;
}

interface InvoiceState {
  clientId: string;
  invoiceNo: string;
}

interface State {
  invoice?: InvoiceState;
  showModal: boolean;
  showPreview: boolean;
  showEmail: boolean;
  print: boolean;
}

//TODO Refactor this whole thing with printing.
const InvoiceTable: React.FC<InvoiceTableProps> = (props) => {
  const tableRef = props.tableRef;
  const notifications = useNotifications();
  const [state, setState] = useState<State>({
    showModal: false,
    showPreview: false,
    showEmail: false,
    print: false,
  });

  const closeEmailModal = () => {
    setState({
      ...state,
      showEmail: false,
      invoice: undefined,
    });
  };

  const closePreview = () => {
    setState({
      ...state,
      showPreview: false,
      invoice: undefined,
    });
  };

  const closeEdit = () => {
    setState({
      ...state,
      showModal: false,
      invoice: undefined,
    });
  };

  const closePrint = () => {
    setState({
      ...state,
      print: false,
      invoice: undefined,
    });
  };

  useEffect(() => {
    const ref = tableRef.current as any;
    ref.onQueryChange();
  }, [props.fetchFunction, tableRef]);
  return (
    <div>
      <MaterialTable
        icons={icons}
        tableRef={tableRef}
        columns={[
          {
            title: "Kliento ID",
            field: "clientId",
          },
          {
            title: "Išrašymo data",
            field: "date",
            type: "date",
            cellStyle: {
              minWidth: "120px",
            },
          },
          {
            title: "Sąskaitos Nr.",
            field: "title",
          },
          {
            title: "Įmonės pavadinimas",
            field: "companyTitle",
          },
          {
            title: "Prenumerata nuo",
            field: "periodFrom",
            type: "date",
            cellStyle: {
              minWidth: "120px",
            },
          },
          {
            title: "Prenumerata iki",
            field: "periodTo",
            type: "date",
            cellStyle: {
              minWidth: "120px",
            },
          },
          { title: "Kaina", field: "price" },
          { title: "PVM", field: "pvm" },
          {
            title: "Nuolaida",
            field: "discount",
          },
          {
            title: "Viso",
            field: "totalCount",
          },
          {
            title: "Siuntimo data",
            field: "lastSentDate",
            type: "date",
            cellStyle: {
              minWidth: "120px",
            },
          },
          {
            title: "Autorius",
            field: "author",
          },
        ]}
        editable={
          {
            onRowDelete: (newData) =>
              InvoiceModel.delete(newData.invoiceNo)
                  .then((r) => {
                    return r;
                  })
                  .catch((err) => {
                    notifications.notify({
                      level: "error",
                      message: err.response.data.description,
                    });
                  }),

            isDeletable: (rowData) => {
              return rowData.isLast;
            },
            deleteTooltip: ()  => {
              return "Ištrinti sąskaitą";
            }
        }}
        options={{
          padding: "dense",
          sorting: false,
          search: false,
          actionsColumnIndex: -1,
          pageSize: 10,
          pageSizeOptions: [10],
          paginationType: "stepped",
          maxBodyHeight: "300px",
          headerStyle: {
            backgroundColor: "#bdbdbd",
          },
          rowStyle: (rowData, index) => {
            if (index % 2) {
              return {};
            }
            return { backgroundColor: "#efefef" };
          },
        }}
        actions={[
          {
            icon: PrintIcon,
            tooltip: "Spausdinti sąskaitą",
            onClick: (event: any, rowData: any) => {
              setState({
                ...state,
                print: true,
                invoice: rowData,
              });
            },
          },
          {
            icon: Edit,
            tooltip: "Redaguoti sąskaitą",
            onClick: (event: any, rowData: any) => {
              setState({
                ...state,
                print: false,
                showModal: true,
                invoice: rowData,
              });
            },
          },
          {
            icon: Description,
            tooltip: "Peržiūrėti sąskaitą",
            onClick: (event: any, rowData: any) => {
              setState({
                ...state,
                print: false,
                showPreview: true,
                invoice: rowData,
              });
            },
          },
          {
            icon: EmailIcon,
            tooltip: "Siųsti sąskaitą",
            onClick: (event: any, rowData: any) => {
              setState({
                ...state,
                print: false,
                showEmail: true,
                invoice: rowData,
              });
            },
          },
        ]}
        localization={{
          header: {
            actions: "",
          },
          body: {
            emptyDataSourceMessage: "Klientas neturi sąskaitų",
            deleteTooltip: "Ištrinti sąskaitą",
            editRow: {
              deleteText: "Ar tikrai ištrinti sąskaitą?",
              saveTooltip: "Ištrinti",
              cancelTooltip: "Atšaukti"
            },
          },

        }}
        data={(query) =>
          props
            .fetchFunction({
              page: query.page,
              size: query.pageSize,
            })
            .then((result) => {
              return {
                data: result.content as InvoiceModel[],
                page: result.number,
                totalCount: result.totalElements,
              };
            })
        }
        title="Sąskaitos"
      />
      {state.invoice && state.showModal && (
        <InvoiceEditModalForm
          close={closeEdit}
          invoiceId={state.invoice.invoiceNo}
          tableRef={tableRef}
          onSuccess={() => {
            setState({
              ...state,
              showPreview: false,
              showModal: false,
              showEmail: true,
              print: false,
            });
          }}
        />
      )}

      {state.invoice && state.showPreview && (
        <InvoiceModalView
          close={closePreview}
          pdfUrl={() => InvoiceModel.pdf(state.invoice?.invoiceNo)}
          sendAction={() => {
            setState({
              ...state,
              showPreview: false,
              showEmail: true,
            });
          }}
        />
      )}

      {state.invoice && state.showEmail && (
        <InvoiceEmailModalForm
          clientId={state.invoice.clientId}
          title={`Siųsti sąskaitą Nr. ${state.invoice.invoiceNo}?`}
          close={closeEmailModal}
          pdfUrl={() => InvoiceModel.pdf(state.invoice?.invoiceNo)}
          onSubmit={async (content) => {
            const invoiceNo = state.invoice?.invoiceNo;
            if (invoiceNo) {
              return await InvoiceModel.send(content, invoiceNo);
            }
            return Promise.reject("Invoice not present!");
          }}
        />
      )}

      {state.invoice && state.print && (
        <InvoicePrintComponent
          close={closePrint}
          pdfUrl={() => InvoiceModel.pdf(state.invoice?.invoiceNo)}
        />
      )}
    </div>
  );
};

export default InvoiceTable;
